html {
  height: 100%;
}

body {
  background-color: #fafafa;
  height: 100%;
}

#root {
  height: 100%;
  overflow-y: auto;
}
